import { PortfolioTransactionModel } from '@models/transactions.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FEATURE_KEY, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getTransactionsExport = (state: State): any =>
  state.transactionsExport;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);

export const selectTransactionExport: MemoizedSelector<
  object,
  PortfolioTransactionModel[]
> = createSelector(selectState, getTransactionsExport);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectDocumentState = createSelector(
  selectState,
  (state: State): any => state.documentState
);

export const selectDocumentId = createSelector(
  selectState,
  (state: State): any => state.documentId
);

export const selectTransactionPrintStatus = createSelector(
  selectDocumentState,
  selectDocumentId,
  (documentState, documentId) => {
    return {
      documentState,
      documentId,
    };
  }
);
