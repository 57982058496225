import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PrintStatusType,
  TransactionsActionParams,
  TransactionsPrintRequestParams,
} from '@models/account.model';
import {
  AccountCategory,
  AccountDto,
  AccountLimitDto,
  AccountOverviewDto,
  CashAccountInformationDto,
  CashAccountTransactionDto,
  CashAccountTransactionFilterOptionsDto,
  CashAccountTransactionView,
  PowerOfAttorneyType,
} from '@models/account.model.new';
import { ClientLimitDto } from '@models/entitlements/client.model';
import { UserActionDto } from '@models/user-action.model';
import { Store } from '@ngrx/store';
import { AccountActions, AccountStore } from '@store/account-store';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, repeat, tap } from 'rxjs/operators';
import {
  CashOrderDto,
  UserDto,
  CashOrderCurrencyDto,
  CashOrderAccountDto,
  ItemTypeDto,
  PreciousMetalItemDto,
  PreciousMetalOrderDto,
} from '@models/account-services.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private store$: Store<AccountStore.State>
  ) {}

  accountOverviewSubject = new BehaviorSubject<AccountOverviewDto | null>(null);
  private orderData = new BehaviorSubject(null);

  getSelectedAccount(accountNo: string | null): Observable<AccountDto | null> {
    if (accountNo) {
      return this.getAccountOverview().pipe(
        map((accountOverview) => {
          return (
            accountOverview.accountGroups
              .flatMap((group) => group.accounts)
              .find((account) => account.accountNo === accountNo) ?? null
          );
        })
      );
    }

    return of(null);
  }

  getAccountOverview(
    opt: { forceFetch: boolean } = { forceFetch: false }
  ): Observable<AccountOverviewDto> {
    if (!opt.forceFetch && this.accountOverviewSubject.value) {
      return of(this.accountOverviewSubject.value);
    }

    return this.http.get<AccountOverviewDto>('/api/v1-legacy/accounts').pipe(
      tap((value) => {
        this.accountOverviewSubject.next(value);
      })
    );
  }

  getAccountTransactions(
    payload: any,
    fetchPendingPayments: Boolean = true
  ): Observable<CashAccountTransactionView> {
    const cashAccountObj = of(<CashAccountTransactionDto[]>{});

    const params = this.createFilterParams(payload, fetchPendingPayments);
    return forkJoin({
      pendingPayments: cashAccountObj,
      transactions: this.http.get<CashAccountTransactionDto[]>(
        `/api/v1-legacy/accounts/${payload.accountNo}/transactions${params}`
      ),
    });
  }

  getAccountTransactionsExport(
    accountId: string,
    payload: any
  ): Observable<string> {
    const params = this.createTransactionsExportFilterParams(payload);
    return this.http.get(
      `/api/v1/accounts/${accountId}/transactions${params}`,
      { responseType: 'text', headers: { Accept: 'text/csv' } }
    );
  }

  getPurposeCodes(
    accountNo: any
  ): Observable<CashAccountTransactionFilterOptionsDto> {
    return this.http.get<CashAccountTransactionFilterOptionsDto>(
      `/api/v1-legacy/accounts/${accountNo}/transaction-filter-options`
    );
  }

  getAccountInformation(
    accountNo: string
  ): Observable<CashAccountInformationDto> {
    return this.http.get<CashAccountInformationDto>(
      `/api/v1-legacy/accounts/${accountNo}/information`
    );
  }

  /// Returns null in case the old backend is used
  getAccountLimits(accountId: string): Observable<AccountLimitDto[]> {
    return this.http.get<AccountLimitDto[]>(
      `/api/v1/accounts/${accountId}/limits`
    );
  }

  getClientLimits(selectedClientId: String): Observable<ClientLimitDto[]> {
    return this.http.get<ClientLimitDto[]>(
      `/api/v1/entitlements/clients/${selectedClientId}/limits`
    );
  }

  postClientLimit(
    selectedClientId: String,
    newLimit: ClientLimitDto
  ): Observable<UserActionDto> {
    return this.http.post<UserActionDto>(
      `/api/v1/entitlements/clients/${selectedClientId}/limits`,
      newLimit
    );
  }

  $clientLimitChanged = new BehaviorSubject<boolean | null>(null);

  /// account Id for new backend otherwise accountNo for old backend
  toggleUserProlongationRmp(opt: {
    accountId?: string;
    prolong: boolean;
  }): Observable<any> {
    return this.http.post<boolean>(
      `/api/v1/accounts/${opt.accountId!}/toggle-user-prolongation`,
      ''
    );
  }

  private createFilterParams(
    payload: any,
    fetchPendingPayments: Boolean | undefined
  ): string {
    let params =
      fetchPendingPayments != undefined
        ? `?include-pending-payments=${fetchPendingPayments}`
        : '';

    if (payload.amountFrom) {
      const parameter = `amount-from=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amount-to=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `date-from=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `date-to=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.page || payload.page == 0) {
      const parameter = `page=${payload.page}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.size) {
      const parameter = `size=${payload.size}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `transaction-purpose-codes=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  private createTransactionsExportFilterParams(payload: any): string {
    let params = `?include-pending-payments=true`;

    if (payload.amountFrom) {
      const parameter = `amount-from=${payload.amountFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.amountTo) {
      const parameter = `amount-to=${payload.amountTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateFrom) {
      const parameter = `date-from=${payload.dateFrom}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.dateTo) {
      const parameter = `date-to=${payload.dateTo}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.search) {
      const parameter = `search=${payload.search}`;
      params = params.length ? `${params}&${parameter}` : `?${parameter}`;
    }
    if (payload.purposeCode?.length > 0) {
      payload.purposeCode.forEach((element: any) => {
        const parameter = `transaction-purpose-codes=${element}`;
        params = params.length ? `${params}&${parameter}` : `?${parameter}`;
      });
    }

    return params;
  }

  printTransactionDocument(
    params: TransactionsPrintRequestParams
  ): Observable<TransactionsActionParams> {
    return this.http.post<any>(
      `/api/v1/accounts/${params.cashAccountId}/transactions/${params.transactionId}/documents`,
      {}
    );
  }

  printPendingPaymentDocument(
    params: TransactionsPrintRequestParams
  ): Observable<TransactionsActionParams> {
    return this.http.post<any>(
      `/api/v1/accounts/${params.cashAccountId}/pending-payments/${params.paymentOrderId}/documents`,
      {}
    );
  }

  getPrintedPdfState(
    documentId: string,
    cashAccountId: string
  ): Observable<any> {
    return this.http.get<any>(
      `/api/v1/accounts/${cashAccountId}/documents/${documentId}/status`
    );
  }

  getPrintedPdf(
    documentId: string,
    cashAccountId: string,
    headers: HttpHeaders = new HttpHeaders({})
  ): Observable<any> {
    const uri = `/api/v1/accounts/${cashAccountId}/documents/${documentId}`;
    return this.http.request<any>('get', uri, {
      headers,
      observe: 'response',
      responseType: 'blob' as 'json',
      withCredentials: false,
    });
  }

  pollPrintStatusResponse(serviceResult: Observable<any>): void {
    this.store$.dispatch(new AccountActions.TransactionPrintStatus());
    const subscription = serviceResult
      .pipe(
        delay(1000),
        catchError((error) => {
          subscription.unsubscribe();
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusFailure(error)
          );
          return throwError(error);
        }),
        repeat()
      )
      .subscribe((resp: TransactionsActionParams) => {
        if (resp.status === PrintStatusType.COMPLETED) {
          this.store$.dispatch(
            new AccountActions.TransactionPrintStatusSuccess(resp)
          );
          subscription.unsubscribe();
        }
      });
  }

  getCurrencies(): Observable<CashOrderCurrencyDto[]> {
    return this.http.get<CashOrderCurrencyDto[]>(
      '/api/v1/liquidity-orders/currencies'
    );
  }

  getSettlementAccounts(
    currencyCode: string
  ): Observable<CashOrderAccountDto[]> {
    const params = new HttpParams().set('ccy-iso', currencyCode);
    return this.http.get<CashOrderAccountDto[]>(
      '/api/v1/liquidity-orders/accounts',
      { params }
    );
  }

  getRecipients(): Observable<UserDto[]> {
    return this.http.get<UserDto[]>('/api/v1/liquidity-orders/users');
  }

  createCashOrder(accountId: string, orderData: CashOrderDto): Observable<any> {
    return this.http.post<any>(
      `/api/v1/liquidity-orders/accounts/${accountId}/cash`,
      orderData
    );
  }

  createPreciousMetalItems(
    accountId: string,
    orderData: PreciousMetalOrderDto
  ): Observable<any> {
    return this.http.post<any>(
      `/api/v1/liquidity-orders/accounts/${accountId}/precious-metal-items`,
      orderData
    );
  }

  getOrderData() {
    return this.orderData.getValue();
  }

  setOrderData(orderData: any) {
    this.orderData.next(orderData);
  }

  getProducts(itemType: string): Observable<PreciousMetalItemDto[]> {
    const params = new HttpParams().set('type', itemType);
    return this.http.get<PreciousMetalItemDto[]>(
      '/api/v1/liquidity-orders/precious-metal-items',
      { params }
    );
  }

  getCategories(): Observable<ItemTypeDto[]> {
    return this.http.get<ItemTypeDto[]>(
      '/api/v1/liquidity-orders/precious-metal-items/types'
    );
  }
}
