import {
  TransactionsActionParams,
  TransactionsPrintRequestParams,
} from '@models/account.model';
import { PortfolioTransactionModel } from '@models/transactions.model';
import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';
export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ClearPrintAction implements Action {
  readonly type = ActionTypes.CLEAR_PRINT_ACTION;
}

export class TransactionPrintRequestAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_REQUEST;
  constructor(public payload: TransactionsPrintRequestParams) {}
}

export class TransactionPrintSuccessAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintFailureAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class TransactionPrintPendingRequestAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_REQUEST;
  constructor(public payload: TransactionsPrintRequestParams) {}
}

export class TransactionPrintPendingSuccessAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintPendingFailureAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class TransactionPrintStatus implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS;
}

export class TransactionPrintStatusSuccess implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintStatusFailure implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class ExportAccountTransactionsAsCsvRequestAction implements Action {
  readonly type = ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_REQUEST;
  constructor(public payload: any) {}
}

export class ExportAccountTransactionsAsCsvSuccessAction implements Action {
  readonly type = ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_SUCCESS;
  constructor(public payload: PortfolioTransactionModel[]) {}
}
