import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AGGREGATED_PORTFOLIO_CODE } from '@core/constants/defaults';
import { PerformanceDateRange } from '@models/performance.model';
import { AllocationPortfolioDto } from '@models/portfolio/allocation.model';
import {
  OverviewData,
  OverviewPortfoliosDto,
  PerformancePortfolioDto,
} from '@models/portfolio/portfolio.model';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  constructor(private http: HttpClient) {}

  private _stateOverView: OverviewData = <OverviewData>{};
  public getOverViewState() {
    return this._stateOverView;
  }

  public setOverViewState(data: OverviewData) {
    this._stateOverView = data;
  }

  getOverViewData(param: PerformanceDateRange): Observable<OverviewData> {
    return forkJoin({
      overviewData: this.getPortfolioList(),
      performceData: this.getAggregationPerformances(param),
      allocationData: this.getAggregationAllocationAssets(),
    });
  }

  portfolioListSubject = new BehaviorSubject<OverviewPortfoliosDto | null>(
    null
  );

  getPortfolioList(): Observable<OverviewPortfoliosDto> {
    return this.http
      .get<OverviewPortfoliosDto>('/api/v1-legacy/portfolios')
      .pipe(
        tap((data) => {
          this.portfolioListSubject.next(data);
        })
      );
  }

  getAggregationAllocationAssets(): Observable<AllocationPortfolioDto> {
    return this.http.get<AllocationPortfolioDto>(
      `/api/v1-legacy/portfolios/${AGGREGATED_PORTFOLIO_CODE}/allocations`
    );
  }

  getAggregationPerformances(
    param: PerformanceDateRange
  ): Observable<PerformancePortfolioDto[]> {
    return this.http.get<PerformancePortfolioDto[]>(
      `/api/v1-legacy/portfolios/${AGGREGATED_PORTFOLIO_CODE}/performance?date-from=${param.dateFrom}&date-to=${param.dateTo}`
    );
  }
}
