import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Language, LanguageService } from '@core/services/language.service';
import { Environment } from '@models/auth-session.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NavigationActions } from '@store/navigation/navigation-store';

@Component({
  selector: 'wmp-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss'],
})
export class SwitchLanguageComponent implements OnInit {
  public languages: Language[] = [];

  constructor(
    public languageService: LanguageService,
    private store$: Store,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  public setLanguage(locale: string): void {
    const isRmp = this.isRmp();
    if (this.authService.getUserLoggedIn() || isRmp) {
      const languageCode = this.languageService.getLanguageCodeByLocale(locale);
      this.store$.dispatch(
        new NavigationActions.SetLanguageRequestAction(languageCode)
      );
    } else {
      this.languageService.setLanguage(locale);
    }
  }

  public getCurrentLanguage(): string {
    return this.languageService.getCurrentLanguage();
  }

  public ngOnInit(): void {
    this.languages = this.languageService.languages;
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  public getButtonClass(locale: string): string {
    if (locale === this.getCurrentLanguage()) {
      return 'active';
    }

    const isRmp = this.isRmp();
    if (this.authService.getUserLoggedIn() || isRmp) {
      return 'inactive-white';
    } else {
      return 'inactive-black';
    }
  }

  getAriaLabel(locale: string): string {
    if (locale === 'de') {
      return this.translate.instant('navigationHeader.language.de');
    } else {
      return this.translate.instant('navigationHeader.language.en');
    }
  }
}
