export interface AccountTransactionFilterModel {
  search?: string;
  amountFrom?: string;
  amountTo?: string;
  dateFrom?: string | null;
  dateTo?: string | null;
  purposeCode?: number[];
  totalAppliedFilters?: number;
}

export interface TransactionsPrintRequestParams {
  cashAccountId?: string;
  transactionId?: string;
  paymentOrderId?: string;
}

export interface TransactionsActionParams {
  documentId?: string;
  paymentOrderId?: any;
  status?: PrintStatusType;
}

export enum PrintStatusType {
  NEW = 'NEW',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  PROCESSED = 'PROCESSED',
}
