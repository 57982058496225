import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  TemplateModel,
  TemplateTransactionsByIbanRequestModel,
  TemplateTransactionsByIbanResponseModel,
} from '@models/template.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private http: HttpClient) {}

  // getTemplateTransactionsByIban(
  //   param: TemplateTransactionsByIbanRequestModel
  // ): Observable<TemplateTransactionsByIbanResponseModel> {
  //   return this.http.get<any>(
  //     `/public/api/v2/templates/${param.accountIban}?search=${param.search}`
  //   );
  // }

  // getTemplates(text: string | undefined): Observable<TemplateModel[]> {
  //   const searchText = text ? `?search=${text}` : '';
  //   return this.http.get<any>(`/public/api/v2/templates${searchText}`);
  // }

  // deleteTemplate(templateId: any) {
  //   return this.http.delete<any>(`/public/api/v2/templates/${templateId}`);
  // }

  // createOrEditTemplate(templateData: TemplateModel) {
  //   return this.http.put<any>(`/public/api/v2/templates`, templateData);
  // }
}
