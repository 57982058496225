<ul>
  <li>
    <ng-container *ngIf="isViewingFaq">
      <button
        id="goBack"
        type="button"
        (click)="navigateToLogin()"
        aria-label="Go Back"
      >
        <span>{{ 'general.button.back' | translate }}</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!isViewingFaq">
      <button
        id="needAssistance"
        (click)="toggleMenu()"
        aria-label="Need Assistance"
        matSuffix
      >
        <span>{{ 'login.needAssistance' | translate }}</span>
        <ng-container>
          <img
            src="../../../../assets/images/icons/close.svg"
            alt="needAssistance"
          />
        </ng-container>
      </button>
    </ng-container>
  </li>
  <li *ngIf="isMenuOpen">
    <button
      id="loginFaq"
      (click)="navigateToFaq()"
      aria-label="Navigate to FAQ"
      matSuffix
    >
      <ng-container>
        <img
          src="../../../../assets/images/icons/help-circle-outline.svg"
          alt="FAQ"
        />
        <span>{{ 'login.faq' | translate }}</span>
      </ng-container>
    </button>
  </li>
  <li *ngIf="isMenuOpen">
    <button id="phoneNumber" aria-label="Call Helpdesk" matSuffix>
      <ng-container>
        <img
          src="../../../../assets/images/icons/headset.svg"
          alt="PhoneNumber"
        />
        <span>+49 40 350 60-789</span>
      </ng-container>
    </button>
  </li>
</ul>
