export const LOAD_FAILURE = '[Account] Load Failure';
export const CLEAR_PRINT_ACTION = '[Account] Clear Account Transactions Print';
export const EXPORT_TRANSACTIONS_AS_CSV_REQUEST =
  '[Account] Export Transactions as CSV Request';
export const EXPORT_TRANSACTIONS_AS_CSV_SUCCESS =
  '[Account] Export Transactions as CSV SUCCESS';
export const TRANSACTION_PRINT_REQUEST = '[Account] Transaction Print Request';
export const TRANSACTION_PRINT_SUCCESS = '[Account] Transaction Print Success';
export const TRANSACTION_PRINT_FAILURE = '[Account] Transaction Print Failure';
export const TRANSACTION_PRINT_PENDING_REQUEST =
  '[Account] Transaction Print Pending Request';
export const TRANSACTION_PRINT_PENDING_SUCCESS =
  '[Account] Transaction Print Pending Success';
export const TRANSACTION_PRINT_PENDING_FAILURE =
  '[Account] Transaction Print Pending Failure';
export const TRANSACTION_PRINT_STATUS = '[Account] Transaction Print Status';
export const TRANSACTION_PRINT_STATUS_SUCCESS =
  '[Account] Transaction Print Status Success';
export const TRANSACTION_PRINT_STATUS_FAILURE =
  '[Account] Transaction Print Status Failure';
