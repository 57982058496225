import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  TemplateActions,
  TemplateActionTypes,
} from '@store/template-store/index';
import { TemplateService } from '@core/services/template/template.service';
import {
  TemplateModel,
  TemplateTransactionsByIbanResponseModel,
} from '@models/template.model';

@Injectable()
export class TemplateEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private templateService: TemplateService
  ) {}
}
