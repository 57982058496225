import { Injectable } from '@angular/core';
import { LANGUAGE_CODE_DE } from '@core/constants/defaults';
import { LanguageService } from '@core/services/language.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentUtilService {
  private modalCancel = new Subject<boolean>();

  modalCancel$ = this.modalCancel.asObservable();

  constructor(
    private languageService: LanguageService,
    private navigationService: NavigationService
  ) {}

  getAvailableText(value: string): string {
    value = value.replace(/[^\u0020-\u007F]/g, '');
    value = value.replace(/\\u00..|\\n|\\f|\\|'/g, '');
    value = value.replace(/<[^>]*>/g, '');
    return value;
  }

  checkZeroValue(value: any) {
    const retValue = parseFloat(value.toString().replace(',', '.'));
    const amount_not_allowed_pattern = '(^[.,].*$)';
    if (value.match(amount_not_allowed_pattern)) {
      return this.languageService.getCurrentLanguage() === LANGUAGE_CODE_DE
        ? retValue.toFixed(2).toString().replace('.', ',')
        : retValue.toFixed(2);
    } else if (retValue == 0) {
      return '';
    }
    return null;
  }

  getLocalizedAmount(value: any) {
    return this.languageService.getCurrentLanguage() === LANGUAGE_CODE_DE
      ? value.toString().replace('.', ',')
      : value;
  }

  public reRoutePage(reRoute: boolean) {
    const mastNum = sessionStorage.getItem('SetMasterNumberRequestAction');
    if (reRoute) {
      if (mastNum) {
        this.navigationService.setSelectedClient(mastNum).subscribe(() => {
          window.location.reload();
        });
        sessionStorage.removeItem('SetMasterNumberRequestAction');
      }
    } else {
      this.cancelModal(true);
    }
  }

  private cancelModal(cancel: boolean) {
    this.modalCancel.next(cancel);
  }
}
