import { Injectable } from '@angular/core';
import { AccountService } from '@core/services/account/account.service';
import { TransactionsActionParams } from '@models/account.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccountActions } from '@store/account-store/index';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AccountActionTypes from './actionTypes';

@Injectable()
export class AccountEffects {
  getDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.TRANSACTION_PRINT_REQUEST),
      mergeMap((action: AccountActions.TransactionPrintRequestAction) => {
        return this.accountService
          .printTransactionDocument(action.payload)
          .pipe(
            map((response: TransactionsActionParams) => {
              if (response.documentId && action.payload.cashAccountId) {
                const serviceResult = this.accountService.getPrintedPdfState(
                  response.documentId,
                  action.payload.cashAccountId
                );
                this.accountService.pollPrintStatusResponse(serviceResult);
                return new AccountActions.TransactionPrintSuccessAction(
                  response
                );
              } else {
                return new AccountActions.TransactionPrintSuccessAction(
                  response
                );
              }
            }),
            catchError((error) => {
              this.store$.dispatch(
                new AccountActions.TransactionPrintFailureAction({
                  error: error.message ? error.message : error,
                })
              );
              return throwError(error);
            })
          );
      })
    );
  });

  getPendingDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.TRANSACTION_PRINT_PENDING_REQUEST),
      mergeMap(
        (action: AccountActions.TransactionPrintPendingRequestAction) => {
          return this.accountService
            .printPendingPaymentDocument(action.payload)
            .pipe(
              map((response: TransactionsActionParams) => {
                if (response.documentId && action.payload.cashAccountId) {
                  const serviceResult = this.accountService.getPrintedPdfState(
                    response.documentId,
                    action.payload.cashAccountId
                  );
                  this.accountService.pollPrintStatusResponse(serviceResult);
                  return new AccountActions.TransactionPrintPendingSuccessAction(
                    response
                  );
                } else {
                  return new AccountActions.TransactionPrintPendingSuccessAction(
                    response
                  );
                }
              }),
              catchError((error) => {
                this.store$.dispatch(
                  new AccountActions.TransactionPrintPendingFailureAction({
                    error: error.message ? error.message : error,
                  })
                );
                return throwError(error);
              })
            );
        }
      )
    );
  });

  constructor(
    private store$: Store,
    private actions$: Actions,
    private accountService: AccountService
  ) {}
}
