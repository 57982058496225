import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function accountReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        transactionsExport: null,
      };
    }

    case ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        transactionsExport: action.payload,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CLEAR_PRINT_ACTION: {
      return {
        ...state,
        documentId: '',
      };
    }

    case ActionTypes.TRANSACTION_PRINT_STATUS_SUCCESS: {
      return {
        ...state,
        documentId: action.payload.documentId,
        documentState: action.payload.documentState,
      };
    }

    default: {
      return state;
    }
  }
}
