export const FEATURE_KEY = 'account';

export interface State {
  isLoading: boolean;
  error?: any;
  documentId?: string;
  documentState?: string;
  transactionsExport?: any;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  transactionsExport: null,
};
