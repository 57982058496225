import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as ActionTypes from './actionTypes';
import { AuthService } from '@core/services/auth.service';
import * as FeatureActions from './actions';
import { Observable, throwError } from 'rxjs';
import { TwoFactorAuthService } from '@core/services/twoFactorAuth/two-factor-auth.service';
import { Store } from '@ngrx/store';
import { AuthSessionType } from '@models/auth-session.model';
import { AuthStore } from '@store/auth-store/index';
import { CookieService } from 'ngx-cookie-service';
import { OnboardingStatusResultModel } from '@models/onboarding.model';

@Injectable()
export class AuthEffects {
  constructor(
    private store$: Store<AuthStore.State>,
    private actions$: Actions,
    private cookieService: CookieService,
    private authService: AuthService,
    private twoFactorAuthService: TwoFactorAuthService
  ) {}

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.REFRESH_TOKEN),
      mergeMap((action: FeatureActions.RefreshToken) =>
        this.authService.refreshToken().pipe(
          map((result) => {
            this.authService.saveTokenToSessionStorage();
            return new FeatureActions.RefreshTokenSuccessAction(result);
          }),
          catchError((error) => {
            return this.handleLoginAttemptFailure(error);
          })
        )
      )
    );
  });

  // cancelAuthSession$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ActionTypes.CANCEL_AUTH_SESSION),
  //     mergeMap((action: FeatureActions.CancelAuthSession) =>
  //       this.twoFactorAuthService.cancelAuthSession(action.session).pipe(
  //         map((result) => {
  //           return new FeatureActions.CancelAttemptSuccessAction(result);
  //         }),
  //         catchError((error) => {
  //           return this.handleCancelAttemptError(error);
  //         })
  //       )
  //     )
  //   );
  // });

  cancelLoginAuthSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.CANCEL_LOGIN_AUTH_SESSION),
      mergeMap((action: FeatureActions.CancelLoginAuthSession) =>
        this.twoFactorAuthService.cancelLoginAuthSession(action.session).pipe(
          map((result) => {
            return new FeatureActions.CancelAttemptSuccessAction(result);
          }),
          catchError((error: any) => {
            return this.handleCancelAttemptError(error);
          })
        )
      )
    );
  });

  getUserRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.GET_USER_ROLES_REQUEST),
      mergeMap(() => {
        return this.authService.getUserRoles().pipe(
          map((data) => new FeatureActions.GetUserRolesSuccess(data)),
          catchError((error) => {
            return this.handleOnboardingAttemptFailure(error);
          })
        );
      })
    );
  });

  private handleLoginAttemptFailure(error: any): Observable<never> {
    this.authService.removeTokenFromSessionStorage();
    this.store$.dispatch(
      new FeatureActions.LoginAttemptFailureAction({ error })
    );
    return throwError(error);
  }

  private handleCancelAttemptError(error: any): Observable<never> {
    this.store$.dispatch(
      new FeatureActions.CancelAttemptErrorAction({ error })
    );
    return throwError(error);
  }

  private handleOnboardingAttemptFailure(error: any): Observable<never> {
    this.store$.dispatch(
      new FeatureActions.GetOnboardingStatusFailureAction({ error })
    );
    return throwError(error);
  }
}
