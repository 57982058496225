import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectChange as MatSelectChange,
} from '@angular/material/legacy-select';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { FeatureService } from '@core/services/feature.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SessionStorageService } from '@core/services/session-storage-util.service';
import { Environment } from '@models/auth-session.model';
import {
  ClientDto,
  SelectedClientDto,
} from '@models/entitlements/client.model';
import { PaymentUtilService } from '@modules/payments/payments.service';
import { Store } from '@ngrx/store';
import { RelationshipinfoAction } from '@store/navigation/relationshipinfo-store';
import { Observable, Subscription, noop } from 'rxjs';

@Component({
  selector: 'wmp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class SecureHeaderComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked
{
  @ViewChild('masterNumberSelectorTrigger')
  public masterNumberSelectorTrigger?: ElementRef;

  @ViewChild('masterNumberSelectorDropdown')
  public masterNumberSelectorDropdown?: MatSelect;

  @ViewChild('masterNumberOptionsDropdown')
  public masterNumberOptionsDropdown?: MatOption;

  private masterNumberSelectorDropdownSelectionChange?: Subscription;
  private masterNumberSelectorDropdownOpenedChange?: Subscription;
  private clientsSubscription?: Subscription;

  public masterNumbers$?: Observable<ClientDto[]>;
  public allUnselectedClients: ClientDto[] = [];
  public allClients: ClientDto[] = [];
  public isWealthRoute = false;
  public pageTitle = '';
  public payUtilSubscriptionMC: Subscription;
  private routerSubscription: Subscription;
  private modalCancel = false;
  public selectedValue?: String;
  public selectedClient!: SelectedClientDto | undefined;

  constructor(
    private store$: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private authService: AuthService,
    private payUtilService: PaymentUtilService,
    private changeDetector: ChangeDetectorRef,
    private navigationService: NavigationService,
    private features: FeatureService
  ) {
    this.routerSubscription = router.events.subscribe(() => {
      this.setPageTitle();
    });

    this.payUtilSubscriptionMC = this.payUtilService.modalCancel$.subscribe(
      (cancel) => {
        this.modalCancel = cancel;
        if (this.modalCancel) {
          this.selectedValue = this.selectedClient?.masterNo;
        }
      }
    );
  }

  private setPageTitle() {
    const activateRouteSnapshot = this.getDeepestActivateRouteSnapshot(
      this.activatedRoute.snapshot.root
    );
    this.isWealthRoute = activateRouteSnapshot.data.hasOwnProperty(
      'isWealthRoute'
    )
      ? activateRouteSnapshot.data.isWealthRoute
      : false;
    this.pageTitle = activateRouteSnapshot.data.hasOwnProperty('pageTitle')
      ? activateRouteSnapshot.data.pageTitle
      : 'Undefined';
  }

  private getDeepestActivateRouteSnapshot(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): ActivatedRouteSnapshot {
    return activatedRouteSnapshot.children.length
      ? this.getDeepestActivateRouteSnapshot(activatedRouteSnapshot.firstChild!)
      : activatedRouteSnapshot;
  }

  public ngOnInit(): void {
    if (sessionStorage.getItem('environment') == '') {
      let leavePoint = false;
      const intervalId = setInterval(() => {
        if (sessionStorage.getItem('environment') !== '') {
          leavePoint = true;
        }

        if (leavePoint) {
          this.setMasterNumberData();
          clearInterval(intervalId);
        }
      }, 500);
    } else {
      this.setMasterNumberData();
    }
  }

  public ngDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.payUtilSubscriptionMC.unsubscribe();
  }

  private setMasterNumberData(): void {
    if (sessionStorage.getItem('environment') === Environment.RMP) {
      this.createMasterNumberDataForRmp();
    } else {
      this.createMasterNumbersData();
    }
  }

  private createMasterNumbersData() {
    this.clientsSubscription = this.navigationService.clients$.subscribe(
      (clients) => {
        if (clients) {
          this.selectedClient = clients.selectedClient;
          this.allClients = clients.clients;
          this.allUnselectedClients = clients.clients.filter(
            (client: ClientDto) =>
              client.masterNo !== clients.selectedClient.masterNo
          );
        }
      }
    );
    this.navigationService.fetchClients().subscribe();
  }

  public ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  public ngAfterViewInit(): void {
    this.masterNumberSelectorDropdownSelectionChange =
      this.masterNumberSelectorDropdown?.selectionChange.subscribe(
        (data: MatSelectChange) => {
          if (this.isPaymentForm()) {
            sessionStorage.setItem('SetMasterNumberRequestAction', data.value);
            this.router
              .navigate(['/portfolio/summary'])
              ?.then()
              .catch(() => noop());
          } else {
            this.navigationService
              .setSelectedClient(data.value)
              .subscribe((result) => {
                this.router
                  .navigate(['/portfolio/summary'])
                  .then(() => {
                    window.location.reload();
                  })
                  .catch(() => noop());
              });
          }
        }
      );
    this.masterNumberSelectorDropdownOpenedChange =
      this.masterNumberSelectorDropdown?.openedChange.subscribe((isOpen) => {
        if (!isOpen) {
          this.dispelMasterNumberSelectorDropdown();
        }
      });
  }

  isPaymentForm(): boolean {
    return (
      this.router.url.indexOf('payments/new/initial') !== -1 ||
      this.router.url.indexOf('payments/new/review') !== -1 ||
      this.router.url.indexOf('payments/new/edit') !== -1
    );
  }

  public conjureMasterNumberSelectorDropdown(): void {
    if (this.allClients.length > 1) {
      this.masterNumberSelectorTrigger?.nativeElement.classList.add('hide');
      this.masterNumberSelectorDropdown?.open();
    }
  }

  public dispelMasterNumberSelectorDropdown(): void {
    this.masterNumberSelectorTrigger?.nativeElement.classList.remove('hide');
    this.masterNumberSelectorDropdown?.close();
  }

  public ngOnDestroy(): void {
    this.masterNumberSelectorDropdownSelectionChange?.unsubscribe();
    this.masterNumberSelectorDropdownOpenedChange?.unsubscribe();
    this.clientsSubscription?.unsubscribe();
  }

  public logout() {
    if (this.isRmp()) {
      this.router
        .navigate(['/rmp'])
        ?.then()
        .catch(() => noop());
    } else {
      this.authService.logout();
    }
  }

  public isRmp() {
    return sessionStorage.getItem('environment') === Environment.RMP;
  }

  public isAClientSelected() {
    return this.isRmp() ? this.sessionStorageService.isAClientSelected() : true;
  }

  private createMasterNumberDataForRmp() {
    this.clientsSubscription = this.navigationService.clients$.subscribe(
      (clients) => {
        this.selectedClient = clients?.selectedClient;
        if (clients) {
          this.store$.dispatch(new RelationshipinfoAction.LoadRequestAction());
        }
      }
    );
    this.navigationService.fetchClients().subscribe();
  }

  public getMasterNumberId(
    clients: Array<ClientDto>,
    client: ClientDto
  ): string {
    return clients
      .findIndex((element: ClientDto) => element === client)
      .toString();
  }
}
